module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Marijn Scholtus – Digital Product Designer', // Navigation and Site Title
  titleAlt: 'linkable', // Title for JSONLD
  description: 'Marijn Scholtus – Digital Product Designer',
  headline: 'Marijn Scholtus – Digital Product Designer', // Headline for schema.org JSONLD
  url: 'https://www.linkable.nl', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  logo: '/logos/logo-1024.png', // Used for SEO
  ogLanguage: 'en-GB', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Linkable', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Marijn Scholtus', // Author for schemaORGJSONLD
  themeColor: '#04AA9C',
  backgroundColor: '#EBF1F2',

  twitter: '@Linkable', // Twitter Username
  facebook: 'Linkable', // Facebook Site Name
  googleAnalyticsID: 'UA-1687178-1',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
