const theme = {
  colors: {
    primary: '#04AA9C',
    bg: '#fff',
    black: '#323232',
    greyLight: '#EBF1F2',
    greyBlue: '#BFEEEB',
    grey: '#869695',
    greyDark: '#056D65',
    greyDarker: '#05554F',
  },
  maxWidth: '1000px',
  maxWidthText: '720px',
  breakpoints: {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px',
  },
}

export default theme
